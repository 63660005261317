#arrow-scroll {
  position: fixed;
  bottom: rem-calc(15);
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  cursor: pointer;

  @include breakpoint(large) {
    bottom: rem-calc(30);
  }

  img {
    width: rem-calc(50);
    height: rem-calc(50);
  }
}

.plus-minus {
  $plusMinusHeight: 4px;

  float: right;
  position: relative;
  width: rem-calc(25);
  height: rem-calc(25);
  margin-right: rem-calc(10);

  @include breakpoint(medium) {
    width: rem-calc(30);
    height: rem-calc(30);
  }

  @include breakpoint(large) {
    width: rem-calc(40);
    height: rem-calc(40);
  }

  span {
    position: absolute;
    width: 100%;
    height: $plusMinusHeight;
    background: $black;
    left: 0;

    &.first {
      top: 50%;
      transform: translateY(-50%);
    }

    &.second {
      top: 50%;
      transition: all 0.25s ease-in-out;
      transform: rotate(270deg) translate($plusMinusHeight/2, 0);
    }
  }
}
