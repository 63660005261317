main {
  padding-bottom: rem-calc(50);
  padding-top: calc(
    5rem + 1rem
  ); // see .logo (also in .opaque .logo) for calculation

  @include breakpoint(large) {
    padding-top: calc(11rem + 2px);
    padding-bottom: rem-calc(100);
  }

  &.no-hero {
    padding-top: calc(
      5rem + 1rem
    ); // see .logo (also in .opaque .logo) for calculation

    @include breakpoint(large) {
      padding-top: calc(10rem + 2rem + 2px);
    }
  }
}
