ul.accordion {
  margin: 0;
}

.accordion-item {
  $plusMinusHeight: 4px;

  //borders
  &:first-of-type {
    border-top: 3px solid $black;
  }
  border-bottom: 3px solid $black;

  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    border: none;
    @include font-size(1.4375, 1.9375);
    font-weight: bold;
    text-decoration: none;
    color: $black;
  }

  &.is-active {
    .accordion-title {
      .plus-minus {
        span {
          &.second {
            transform: rotate(0) translate(0, -50%);
          }
        }
      }
    }
  }

  .accordion-content {
    padding: 0;

    a + a {
      margin-left: 2em;
    }
  }
}
