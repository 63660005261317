.swiper {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    display: inline-block;
    z-index: 1;
    cursor: pointer;
  }

  .swiper-button-prev {
    left: 1rem;

    .svg-icon {
      transform: rotate(90deg);
    }
  }

  .swiper-button-next {
    right: 1rem;

    .svg-icon {
      transform: rotate(-90deg);
    }
  }
}

#swiper-moodboard {
  img {
    width: 100%;
    margin-bottom: 2rem;
  }
}
