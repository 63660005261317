.orbit {
  .orbit-bullets {
    position: absolute;
    right: 2rem;
    bottom: calc(1rem + $inclined-height);

    > button {
      cursor: pointer;
      background-color: $black;

      // every button element after the element with the class .is-active
      &.is-active ~ button {
        background-color: $white;
      }
    }
  }
}

.orbit-bullets button {
  @include breakpoint(small only) {
    margin: 5px;
  }
}