a {
  &.underline {
    border-bottom: 0.1em solid $black;

    &:hover {
      text-decoration: none;
      border-bottom-color: rgba($black, 0.5);
    }
  }
}
