footer {
  background-color: $gray-dark;
  color: $black;
  padding: 2rem;
  position: relative;
  flex-grow: 1;

  @include breakpoint(large) {
    padding-bottom: rem-calc(80);

  }
  @include breakpoint(1600) {
    font-size: rem-calc(22);
  }
  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: $inclined-height;
    clip-path: polygon(1% 100%, 100% 100%, 100% 0, 1% 100%);
    transform: translateY(-100%);
    background-color: $gray-dark;
  }

  a {
    color: $black;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .icons-container {
    margin-top: 2rem;
  }

  .menu {
    font-size: 1rem;
    left: -0.5em;
    margin-bottom: rem-calc(6);
    @include breakpoint(medium) {
      left: -1.5em;
    }
    @include breakpoint(1600) {
      font-size: rem-calc(22);
    }
    li {
      position: relative;

      &::after {
        content: "|";
        font-size: 1.1em;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateX(70%);
        //color: $black;
      }

      &:last-of-type {
        margin-right: 0;
        padding-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        padding: 0.2em 0.5em;
        //color: $black;

        @include breakpoint(medium) {
          padding: 0.2em 1.5em;
        }

        &:hover {
          text-decoration: none;
        }
      }
      &.active {
        > a {
          text-decoration: none;
          background-color: transparent;
          color: $white;
        }
      }
    }
  }


  .menu {
    @include breakpoint(small down) {
      margin-top: rem-calc(30);
    }
  }
}
