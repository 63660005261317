// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.txt-over-img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $white;
  margin-bottom: 0;

  p {
    &.name {
      @include font-size(1, 5.625);
    }

    &.msg {
      @include font-size(1.75, 8.125);
    }
  }
}

.dividing-line {
  width: 100%;
  height: 3px;
  background-color: $black;
  transform: rotate(-1.5deg);
  margin: 4rem 0;
}

.textbox {
  padding: rem-calc(10);
}

.grecaptcha-badge {
  right: -10000px !important;
}
