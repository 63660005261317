.home-teaser-grid {
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1.7em;
  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  .teaser-container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    a {
      img {
        width: 100%;
      }

      .txt-over-img {
        @include font-size(2, 5);
      }
    }
  }
}

.grid-3-columns {
  display: grid;
  grid-gap: 0.5rem;

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    background-color: $gray-dark;
    padding: 1rem;
    padding-bottom: 3rem;

    @include breakpoint(large) {
      padding: 2rem;
      padding-bottom: 6rem;
    }
  }
  &.no-padding a {
    padding: 0;
    overflow: hidden;
    img {
      transition: all .2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}
