.logo {
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  @include breakpoint(large) {
    padding: 2rem;
    margin: 1rem 1rem 2px 1rem;
  }

  a {
    display: inline-block;

    img {
      width: 5rem;
      height: auto; //immer width und height in PX angeben (IE)
      transition: all 0.2s ease-in-out;

      @include breakpoint(large) {
        width: 10rem;
      }
    }
  }
}
