.menu {
  font-size: rem-calc(22);

  li {
    position: relative;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    a {
      padding: 0.8em;

      &:hover {
        text-decoration: line-through;
        text-decoration-color: $black;
        text-decoration-thickness: 0.2em;
      }
    }
    &.active {
      > a {
        text-decoration: line-through;
        text-decoration-color: $black;
        text-decoration-thickness: 0.2em;
        background-color: transparent;
        color: $black;
      }
    }
  }
}

#menu-desktop {
  @include breakpoint(1200) {
    margin-left: rem-calc(80);
  }
  @include breakpoint(1600) {
    margin-left: rem-calc(160);
  }
  .menu li a::after {
    right: -10px;
  }
}
