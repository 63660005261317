.icon-sprites.svg-icon {
  display: none;
}

.svg-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 1.8em;
  width: 1.8em;

  &.instagram {
    width: 8.143rem;
    height: 2rem;
  }
}

.svg-icon svg {
  bottom: -0.1em;
  position: absolute;
  fill: $black;
  height: inherit;
  width: inherit;
  &:hover {
    fill: lighten($black, 20%);
  }
}
