.long-txt-container {
  h1 {
    @include font-size(1.2, 2);
  }
}

.gallery-container {
  &.gallery-margin-top {
    margin-top: 2.5em;
  }
  img {
    width: 100%;
  }
}

.hero-container {
  $height: 90vh;

  height: $height;
  margin-bottom: 5rem;

  img {
    width: 100%;
    height: $height;
    object-fit: cover;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  .txt-over-img {
    @include font-size(2.6, 10);
    font-weight: normal;
    width: 90%;
    max-width: 13ch;
  }
}

.clipped-container {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 0;
    width: 100%;
    height: $inclined-height;
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);
    background-color: $white;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: $inclined-height;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0, 0% 100%);
    background-color: $white;
  }
}

.teaser-container {
  position: relative;
  overflow: hidden;

  img {
    transition: all 0.2s ease-in-out;
  }

  a {
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  &:hover {
    img {
      transform: scale(1.03);
      filter: brightness(50%);
    }
  }

  &.work-teaser-container {
    &:hover {
      .txt-over-img {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .txt-over-img {
      display: inline-block;
      bottom: rem-calc(18);
      left: rem-calc(20);
      right: rem-calc(20);
      top: auto;
      width: auto;
      transition: all 0.2s ease-in-out;
      opacity: 0;
      transform: translateY(100%);
      text-align: left;
      @include breakpoint(large) {
        left: rem-calc(30);
        right: rem-calc(40);
      }
    }
  }
}

.alpabeth {
  margin-top: 2em;
}
