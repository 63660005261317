header {
  &.h-fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all 0.2s ease-in-out;

    &.opaque {
      // not working because a part is overlayed by the inclined part (.inner-header::after)
      //@include shadow(0 2px 7px 0 rgba($black, 0.1));

      .logo {
        margin-top: 0;
        @include breakpoint(medium) {
          margin-bottom: 1em;
        }
        a img {
          width: 3rem;

          @include breakpoint(large) {
            width: 5rem;
          }
        }
      }
    }
  }

  .inner-header {
    position: relative;
    background-color: $white;

    &:not(.no-inclined) {
      clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - $inclined-height),
        0 100%,
        0 0
      );
    }
  }
}
