@font-face {
  font-family: "HelveticaNow";
  src: url("../fonts/3A9C6A_0_0.eot");
  src: url("../fonts/3A9C6A_0_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3A9C6A_0_0.woff2") format("woff2"),
    url("../fonts/3A9C6A_0_0.woff") format("woff"),
    url("../fonts/3A9C6A_0_0.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNow";
  src: url("../fonts/3A9C6A_1_0.eot");
  src: url("../fonts/3A9C6A_1_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/3A9C6A_1_0.woff2") format("woff2"),
    url("../fonts/3A9C6A_1_0.woff") format("woff"),
    url("../fonts/3A9C6A_1_0.ttf") format("truetype");
  font-weight: bold;
}

main {
  @include font-size(1, 1.875);
}

h1,
h2,
h3,
p,
button.button,
form label,
form .input-container input,
form textarea,
form .form-error {
  @include font-size(1, 1.875);
}

a:focus {
  outline: none;
}
a:visited {
  color: inherit !important;
}

h2 {
  line-height: 1.27;
  margin-bottom: 1.1em;
}

main p {
  margin-bottom: 1.1em;
}

//Animation Home
h1.txt-over-img {
  text-shadow: 0 0 5px rgba(0,0,0,0.05), 0 0 22px rgba(0,0,0,0.1);
  line-height: 1.1;
	span {
      display: inline-block;
      margin: 0 0.2em;
      opacity: 0;
      filter: blur(8px);
      @for $i from 0 through 6 {
        &:nth-child(#{$i + 1}) {
          animation: blur-text 1.1s (#{$i/3})+s 1 ease-in normal;
          animation-fill-mode: forwards;
        }
      }
	}
}

@keyframes blur-text {
  0% { opacity: 0; }
  10% {filter: blur(8px); }
  50% {filter: blur(4px); }
  100% { opacity: 1; filter: blur(0px); }
}
