$transition: all 0.5s ease-out;

.news-container {
  .textbox {
    h3 {
      display: inline-block; // so plus-minus button flows properly
    }

    .introtext {
      display: block;
    }

    .content {
      max-height: 1px;
      opacity: 0;
      overflow: hidden;
      transition: all .3s ease-in-out;
    }

    button.read-more-toggle {
      border-bottom: 2px solid $black;
      color: $black;
      @include breakpoint(large) {
        border-width: 3px;
      }
    }
  }

  .other-images {
    display: none;
  }

  &.opened {
    .introtext {
      //display: none;
    }

    .content {
      max-height: 10000px;
      opacity: 1;
    }

    .plus-minus {
      span {
        &.second {
          transform: rotate(0) translate(0, -50%);
        }
      }
    }

    .other-images {
      display: block;
    }
  }
}


button.read-more-toggle.second {
  visibility: hidden;
}

.news-container.opened {
  button.read-more-toggle.first {
    visibility: hidden;
  }
  button.read-more-toggle.second {
    visibility: visible;
  }
}

.news-img {
  margin-top: rem-calc(20);
  img {
    margin-bottom: rem-calc(20);
    @include breakpoint(1200) {
      //max-width: 80%;
    }
  }
}
