.cookieconsent {
  position: fixed;
  top: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black, 0.75);
  color: $white;
  padding: rem-calc(10);
  z-index: 99999999 !important;
  p {
    margin: 0;
  }
  a {
    color: $white;
  }
  .button {
    font-size: 0.8rem;
    background: transparent;
    border-color: $white;
    color: $white;
    margin: rem-calc(10) auto;
    display: block;
    &:hover {
      background: rgba($white, 0.2);
    }
  }
}
